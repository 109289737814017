.home_logo{
    width: auto;
    height: 64px;
    align-content: center;
    text-align: center;
    line-height:64px;
}

.logo{
    width: 35px;
    height: 35px;
    border-radius: 25px;
    margin-right: 16px;
    /* margin-left: 15px; */
    vertical-align: top;
    position: relative;
    top:50%;
    transform:translateY(-50%);
  }
  .title{
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
    position: relative;
    font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  .title_right{
    display: flex;
    float: right;
    height: 64px;
    margin-left: auto;
    margin-right: 60px;
    overflow: hidden;
  }
.title_right:hover{
    background-color: #022f58;
}
  .login_avatar{
    width: 25px;
    border-radius: 25px;
    border: #ccc;
    height: 25px;
  }

  .login_name{
    color: #ffffff;
    margin-left: 10px;
    font-size: 16sp;
    position: relative;
    font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  .login_layout{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 12px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
  }

  .card{
    width: 24%;
    box-sizing: border-box;
    margin: 0;
    margin-right: 25px;
    padding: 20px;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum","tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
  }
  .card_num{
    height: 38px;
    margin-top: 4px;
    margin-bottom: 0;
    overflow: hidden;
    color: rgba(0,0,0,.85);
    font-size: 30px;
    line-height: 38px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .card_title{
    font-weight: 500;
    font-size: 25px;
    color: black;
  }

  .card_sub_title{
    color: rgb(228, 228, 228);
    font-size: 18px;
  }
  .view_line{
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(236, 236, 236);
    height: 1px;
    width: 100%;
  }
  .span{
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 1.5px;
    margin-right: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background-color: #fafafa;
    border-radius: 20px;
  }
  
  .span_content{
    width: 80%;
    margin-right: 8px;
  }

  .avatar{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border-style: solid;
    border-width: 0.5px;
    border-color: #ccc;
}

.body{
    background-color: #fff;
    padding: 20px;
}