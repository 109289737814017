
.home{
  overflow: hidden;
  background-size:cover;
  width: 100%;
  height: 100%;
}
.loginBox{
  padding-top: 80px;
  width: 300px;
  margin:0 auto;
  align-content: center;
  text-align: center;
}

.logo{
  width: 44px;
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}
.title{
  font-size: 25px;
  color: #313131;
  font-weight: 600;
  font-size: 33px;
  position: relative;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.inputBody{
  width: 300px;
  height: auto;
  margin-top: 30px;
  position: relative;
  text-align: center;
  align-content: center;
}