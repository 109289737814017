.avatar{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border-style: solid;
    border-width: 0.5px;
    border-color: #ccc;
}

.body{
    background-color: white;
    padding: 20px;
}